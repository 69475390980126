// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cennik-jsx": () => import("./../../../src/pages/cennik.jsx" /* webpackChunkName: "component---src-pages-cennik-jsx" */),
  "component---src-pages-galeria-jsx": () => import("./../../../src/pages/galeria.jsx" /* webpackChunkName: "component---src-pages-galeria-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../../../src/pages/o-nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */),
  "component---src-pages-zabiegi-depilacja-jsx": () => import("./../../../src/pages/zabiegi/depilacja.jsx" /* webpackChunkName: "component---src-pages-zabiegi-depilacja-jsx" */),
  "component---src-pages-zabiegi-index-jsx": () => import("./../../../src/pages/zabiegi/index.jsx" /* webpackChunkName: "component---src-pages-zabiegi-index-jsx" */),
  "component---src-pages-zabiegi-oprawa-oka-jsx": () => import("./../../../src/pages/zabiegi/oprawa-oka.jsx" /* webpackChunkName: "component---src-pages-zabiegi-oprawa-oka-jsx" */),
  "component---src-pages-zabiegi-pielegnacja-dloni-jsx": () => import("./../../../src/pages/zabiegi/pielegnacja-dloni.jsx" /* webpackChunkName: "component---src-pages-zabiegi-pielegnacja-dloni-jsx" */),
  "component---src-pages-zabiegi-pielegnacja-stop-jsx": () => import("./../../../src/pages/zabiegi/pielegnacja-stop.jsx" /* webpackChunkName: "component---src-pages-zabiegi-pielegnacja-stop-jsx" */),
  "component---src-pages-zabiegi-przekluwanie-uszu-jsx": () => import("./../../../src/pages/zabiegi/przekluwanie-uszu.jsx" /* webpackChunkName: "component---src-pages-zabiegi-przekluwanie-uszu-jsx" */),
  "component---src-pages-zabiegi-zabiegi-dla-kobiet-w-ciazy-jsx": () => import("./../../../src/pages/zabiegi/zabiegi-dla-kobiet-w-ciazy.jsx" /* webpackChunkName: "component---src-pages-zabiegi-zabiegi-dla-kobiet-w-ciazy-jsx" */),
  "component---src-pages-zabiegi-zabiegi-dla-mezczyzn-jsx": () => import("./../../../src/pages/zabiegi/zabiegi-dla-mezczyzn.jsx" /* webpackChunkName: "component---src-pages-zabiegi-zabiegi-dla-mezczyzn-jsx" */),
  "component---src-pages-zabiegi-zabiegi-na-cialo-jsx": () => import("./../../../src/pages/zabiegi/zabiegi-na-cialo.jsx" /* webpackChunkName: "component---src-pages-zabiegi-zabiegi-na-cialo-jsx" */),
  "component---src-pages-zabiegi-zabiegi-na-twarz-jsx": () => import("./../../../src/pages/zabiegi/zabiegi-na-twarz.jsx" /* webpackChunkName: "component---src-pages-zabiegi-zabiegi-na-twarz-jsx" */),
  "component---src-pages-zespol-jsx": () => import("./../../../src/pages/zespol.jsx" /* webpackChunkName: "component---src-pages-zespol-jsx" */)
}

