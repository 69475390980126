import './node_modules/slick-carousel/slick/slick.css'
import './node_modules/slick-carousel/slick/slick-theme.css'
import './node_modules/leaflet/dist/leaflet.css'
import './src/styles/fonts.css'
import './src/styles/sliders.css'
import './src/styles/gallery.css'
require('typeface-rubik')

// import React from 'react'
// import Layout from './src/components/layout'
// import { GlobalStyle } from './src/styles/globalStyle'

// export const wrapRootElement = ({ element }) => (
//   <>
//     <GlobalStyle />
//     <Layout>{element}</Layout>
//   </>
// )
